import { assignInlineVars } from '@vanilla-extract/dynamic';
import { iconPrimaryColorVar } from '../iconColorVars.css';
import { useSVGSprite } from '../DynamicSVGSpritesheetProvider';

const VideoClapboardIconSprite = ({ id }: { id: string }) => (
  <symbol id={id} viewBox="0 0 32 32">
    <g
      fill="none"
      fillRule="evenodd"
      stroke={iconPrimaryColorVar}
      strokeLinecap="round"
      strokeLinejoin="round"
    >
      <path d="M27 16v12a1 1 0 0 1-1 1H6a1 1 0 0 1-1-1V16a1 1 0 0 1 1-1h20a1 1 0 0 1 1 1zM25.227 5.828l.585 1.912a1 1 0 0 1-.664 1.249L6.022 14.836a1 1 0 0 1-1.249-.664l-.585-1.912a1 1 0 0 1 .664-1.249l19.126-5.847a1 1 0 0 1 1.249.664z" />
      <path d="M8.2 9.988l-.744 4.41.743-4.41zm4.78-1.462l-.742 4.41.743-4.41zm4.782-1.462l-.743 4.41.743-4.41zm4.782-1.462l-.743 4.41.743-4.41zM27 16v2a1 1 0 0 1-1 1H6a1 1 0 0 1-1-1v-2a1 1 0 0 1 1-1h20a1 1 0 0 1 1 1zM9.5 15l-2 4 2-4zm5 0l-2 4 2-4zm5 0l-2 4 2-4zm5 0l-2 4 2-4z" />
    </g>
  </symbol>
);

interface VideoClapboardIconProps extends React.ComponentPropsWithRef<'svg'> {
  color?: string;
  title?: string;
}

export const VideoClapboardIcon = ({ color, title, style, ...props }: VideoClapboardIconProps) => {
  const iconID = useSVGSprite(VideoClapboardIconSprite);

  return (
    <svg
      width="32"
      height="32"
      viewBox="0 0 32 32"
      xmlns="http://www.w3.org/2000/svg"
      style={{
        ...assignInlineVars({
          [iconPrimaryColorVar]: color,
        }),
        ...style,
      }}
      {...props}
    >
      {title ? <title>{title}</title> : null}
      <use href={`#${iconID}`} />
    </svg>
  );
};
