import {
  ModalOverlay as AriaModalOverlay,
  Modal as AriaModal,
  Dialog as AriaDialog,
  DialogProps as AriaDialogProps,
} from 'react-aria-components';
import classNames from 'classnames';
import { useEffect, useRef } from 'react';

import * as styles from './Modal.css';

export interface ModalProps
  extends Omit<
    React.ComponentProps<typeof AriaModal>,
    'children' | 'isDismissable' | 'isKeyboardDismissDisabled' | 'style'
  > {
  /**
   * Whether the modal can be dismissed by clicking outside of it or pressing the escape key.
   * Note that this will not protect against programmatic dismissals, so close buttons
   * inside the modal will need to be separately disabled!
   *
   * @default true
   */
  isDismissable?: boolean;
  /**
   * Optional size preset to apply to the modal.
   * Set to null or "custom" to disable the default size preset and apply your own width.
   *
   * @default "small"
   */
  size?: `${styles.ModalSize}` | null;
  /**
   * Whether this modal is an alert dialog, meaning it is a high priority alert such as
   * a delete confirmation or a critical error message. This will set the dialog role for
   * accessibility purposes.
   *
   * @default false
   */
  isAlertDialog?: boolean;
  /**
   * Optional aria label for the dialog element, used for accessibility purposes if the
   * dialog doesn't have a visible heading.
   */
  dialogLabel?: string;
  /**
   * Cleanup function to call when the modal has fully animated out and unmounted.
   */
  onCleanup?: () => void;
  /**
   * Inline styles to apply to the dialog element.
   */
  style?: AriaDialogProps['style'];
  /**
   * Child contents to render in the modal.
   * This can be a callback function which receives an object with a `close` method,
   * which can be called to close the modal from within the child contents.
   */
  children?: AriaDialogProps['children'];
}

/**
 * A sentinel component that calls a cleanup function when it unmounts.
 * We'll use this to call the `onCleanup` prop when the modal has fully animated out and unmounted
 * because React Aria doesn't expose any APIs for that.
 */
const UnmountSentinel = ({ onUnmount }: { onUnmount?: () => void }) => {
  const unmountRef = useRef<typeof onUnmount>();
  unmountRef.current = onUnmount;

  useEffect(() => () => unmountRef.current?.(), []);

  return null;
};

/**
 * A modal dialog component. The dialog element will be rendered as a child of the <body> element to avoid potential stacking context weirdness.
 */
export const Modal = ({
  isOpen,
  size = styles.ModalSize.small,
  isDismissable = true,
  isAlertDialog = false,
  className,
  style,
  children,
  onCleanup,
  ...props
}: ModalProps) => (
  <AriaModalOverlay
    isOpen={isOpen}
    isDismissable={isDismissable}
    isKeyboardDismissDisabled={!isDismissable}
    className={styles.ModalOverlay}
    {...props}
  >
    <AriaModal
      className={classNames(styles.Modal, className)}
      // If size is explicitly set to null, fall back to "custom" size
      {...styles.dataModalSize(size || styles.ModalSize.custom)}
    >
      <AriaDialog
        role={isAlertDialog ? 'alertdialog' : 'dialog'}
        className={styles.ModalDialog}
        style={style}
      >
        {children}
      </AriaDialog>
      <UnmountSentinel onUnmount={onCleanup} />
    </AriaModal>
  </AriaModalOverlay>
);
