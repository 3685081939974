export const ElevenLabsIcon = () => (
  <svg
    width="73.008232mm"
    height="7.7049313mm"
    viewBox="0 0 73.008232 7.7049313"
    version="1.1"
    id="svg186"
    xmlns="http://www.w3.org/2000/svg"
  >
    <g id="layer1" transform="translate(-2.2132474,-2.0549735)">
      <g aria-label="IIElevenLabs" id="text244">
        <path d="M 2.2132474,2.0549736 H 4.55935 V 9.6307144 H 2.2132474 Z" id="path514" />
        <path d="M 6.3318459,2.0549736 H 8.6779484 V 9.6307144 H 6.3318459 Z" id="path511" />
        <path
          d="m 10.352259,2.0549736 h 6.273499 v 1.6174671 h -3.927396 v 1.2040571 h 3.643177 v 1.5451204 h -3.643177 v 1.4934441 h 4.041084 v 1.7156521 h -6.387187 z"
          id="path508"
        />
        <path d="M 17.933168,2.0549736 H 20.04156 V 9.6307144 H 17.933168 Z" id="path505" />
        <path
          d="m 27.477774,7.4086349 h -4.216783 q 0.05684,0.5064274 0.273884,0.7544735 0.30489,0.3565663 0.795814,0.3565663 0.310058,0 0.58911,-0.1550288 0.170532,-0.098185 0.366901,-0.346231 l 2.072219,0.1912022 q -0.475422,0.8268202 -1.147213,1.1885541 -0.671792,0.3565663 -1.927525,0.3565663 -1.090369,0 -1.715652,-0.30489 Q 21.943246,9.1397899 21.529836,8.473166 21.121593,7.8013745 21.121593,6.8970399 q 0,-1.2867391 0.821653,-2.0825536 0.82682,-0.7958146 2.278923,-0.7958146 1.178219,0 1.860346,0.3565663 0.682127,0.3565662 1.038693,1.0335253 0.356566,0.6769591 0.356566,1.7621608 z M 25.338377,6.4009477 q -0.06201,-0.60978 -0.330728,-0.8733289 -0.263549,-0.263549 -0.69763,-0.263549 -0.50126,0 -0.800982,0.3979073 -0.191202,0.248046 -0.242879,0.7389706 z"
          id="path502"
        />
        <path
          d="m 27.544955,4.1426948 h 2.191073 l 1.064531,3.4778128 1.100705,-3.4778128 h 2.123895 l -2.3306,5.4880196 h -1.865513 z"
          id="path499"
        />
        <path
          d="m 40.453684,7.4086349 h -4.216783 q 0.05684,0.5064274 0.273884,0.7544735 0.30489,0.3565663 0.795815,0.3565663 0.310057,0 0.589109,-0.1550288 0.170532,-0.098185 0.366902,-0.346231 l 2.072218,0.1912022 q -0.475422,0.8268202 -1.147213,1.1885541 -0.671792,0.3565663 -1.927525,0.3565663 -1.090369,0 -1.715652,-0.30489 Q 34.919156,9.1397899 34.505746,8.473166 34.097503,7.8013745 34.097503,6.8970399 q 0,-1.2867391 0.821653,-2.0825536 0.82682,-0.7958146 2.278923,-0.7958146 1.178219,0 1.860346,0.3565663 0.682127,0.3565662 1.038693,1.0335253 0.356566,0.6769591 0.356566,1.7621608 z M 38.314287,6.4009477 q -0.06201,-0.60978 -0.330728,-0.8733289 -0.263549,-0.263549 -0.69763,-0.263549 -0.50126,0 -0.800982,0.3979073 -0.191202,0.248046 -0.242878,0.7389706 z"
          id="path496"
        />
        <path
          d="m 41.420031,4.1426948 h 1.958531 v 0.8939994 q 0.439248,-0.5477684 0.888832,-0.7803116 0.449583,-0.2377109 1.095537,-0.2377109 0.873329,0 1.364253,0.5219303 0.496092,0.5167627 0.496092,1.6019643 v 3.4881481 h -2.113559 v -3.017894 q 0,-0.5167627 -0.191202,-0.7286354 -0.191202,-0.2170403 -0.537433,-0.2170403 -0.382405,0 -0.620116,0.2893871 -0.23771,0.2893871 -0.23771,1.038693 v 2.6354896 h -2.103225 z"
          id="path493"
        />
        <path
          d="m 48.618536,2.0549736 h 2.340935 v 5.7102276 h 3.653512 v 1.8655132 h -5.994447 z"
          id="path490"
        />
        <path
          d="M 57.455179,5.9203584 55.444972,5.7084857 q 0.113688,-0.5270979 0.325561,-0.8268203 0.21704,-0.30489 0.620115,-0.5270979 0.289387,-0.1601965 0.795814,-0.2480461 0.506428,-0.08785 1.095537,-0.08785 0.945676,0 1.519283,0.1085202 0.573606,0.1033525 0.956011,0.4392483 0.268716,0.2325432 0.423745,0.6614562 0.155029,0.4237454 0.155029,0.8113174 v 2.423617 q 0,0.387572 0.04651,0.6097799 0.05168,0.2170403 0.217041,0.5581037 H 59.625582 Q 59.506727,9.4188417 59.470553,9.3103216 59.43438,9.1966338 59.398207,8.9589229 q -0.41341,0.3979073 -0.821653,0.568439 -0.558104,0.2273756 -1.297074,0.2273756 -0.981849,0 -1.493444,-0.4547512 -0.506428,-0.4547512 -0.506428,-1.121375 0,-0.6252828 0.366902,-1.0283577 0.366901,-0.4030749 1.353918,-0.5994447 1.183386,-0.2377109 1.534785,-0.3307282 0.351399,-0.098185 0.744138,-0.2532137 0,-0.387572 -0.160196,-0.5426008 -0.160197,-0.1550288 -0.563271,-0.1550288 -0.516763,0 -0.775144,0.1653641 -0.201538,0.1291906 -0.325561,0.4857569 z m 1.824172,1.1058721 Q 58.845271,7.1812593 58.375017,7.3001147 57.734231,7.4706464 57.563699,7.6360105 57.388,7.8065422 57.388,8.0235825 q 0,0.2480461 0.170532,0.4082425 0.175699,0.1550288 0.511595,0.1550288 0.351398,0 0.651121,-0.1705317 0.30489,-0.1705317 0.428913,-0.4134101 0.12919,-0.2480461 0.12919,-0.6407857 z"
          id="path487"
        />
        <path
          d="M 62.710655,2.0549736 H 64.83455 V 4.680128 q 0.315225,-0.3307281 0.713132,-0.4960922 0.403075,-0.1653641 0.888832,-0.1653641 1.00252,0 1.658808,0.7234678 0.656289,0.7183001 0.656289,2.0670507 0,0.8991671 -0.299722,1.5864614 -0.299723,0.6821268 -0.831988,1.0231901 -0.527098,0.3358958 -1.173051,0.3358958 -0.552937,0 -1.012855,-0.2377109 Q 65.087764,9.3309921 64.679521,8.819397 v 0.8113174 h -1.968866 z m 2.108392,4.8162281 q 0,0.7079649 0.263549,1.0283577 0.268717,0.3152253 0.676959,0.3152253 0.377237,0 0.630451,-0.3100576 0.258381,-0.3152253 0.258381,-1.0541959 0,-0.651121 -0.253214,-0.9560109 -0.248046,-0.30489 -0.604612,-0.30489 -0.428913,0 -0.702797,0.3203928 -0.268717,0.3152253 -0.268717,0.9611786 z"
          id="path484"
        />
        <path
          d="m 69.38723,8.1114321 2.087721,-0.1963698 q 0.129191,0.3720692 0.361734,0.5322656 0.232543,0.1601964 0.620115,0.1601964 0.423746,0 0.656289,-0.1808669 0.180867,-0.1343583 0.180867,-0.3358958 0,-0.2273755 -0.237711,-0.3513986 Q 72.885713,7.6515134 72.15191,7.5223227 71.056373,7.3311205 70.62746,7.1709241 70.203715,7.00556 69.90916,6.617988 69.619773,6.230416 69.619773,5.7343238 q 0,-0.5426008 0.315225,-0.9353404 0.315226,-0.3927397 0.868162,-0.5839418 0.552936,-0.1963699 1.483109,-0.1963699 0.981849,0 1.446935,0.1498612 0.470254,0.1498612 0.780312,0.4650864 0.315225,0.3152253 0.52193,0.8526584 l -1.994704,0.1963699 q -0.07751,-0.263549 -0.258381,-0.387572 -0.248046,-0.1653641 -0.599445,-0.1653641 -0.356566,0 -0.52193,0.1291907 -0.160197,0.124023 -0.160197,0.3048899 0,0.2015375 0.206705,0.30489 0.206706,0.1033526 0.899168,0.1860346 1.049028,0.1188554 1.560623,0.3307281 0.511595,0.2118727 0.780311,0.6046123 0.273885,0.3927397 0.273885,0.8629937 0,0.4754217 -0.289387,0.9250052 -0.28422,0.4495835 -0.904335,0.7183001 -0.614948,0.263549 -1.679479,0.263549 -1.503779,0 -2.144565,-0.428913 -0.635618,-0.4289131 -0.816485,-1.21956 z"
          id="path481"
        />
      </g>
    </g>
  </svg>
);
