export const ThumbsUpIcon = (props: React.ComponentPropsWithoutRef<'svg'>) => (
  <svg
    width="32"
    height="32"
    viewBox="0 0 32 32"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <mask id="path-1-inside-1_4742_69233" fill="white">
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M16.1198 8.11955C16.1198 5.42711 14.3811 3.14076 11.9652 2.3221C10.9191 1.96759 10 2.89516 10 3.99973V11.9997V13.9997V27.9997C10 29.1043 10.8954 29.9997 12 29.9997H25.4512C26.4639 29.9997 27.3167 29.2428 27.437 28.2373L28.9779 15.3561C29.1915 13.5707 27.7973 11.9997 25.9992 11.9997H18.1198C17.0153 11.9997 16.1198 11.1043 16.1198 9.99973V8.11955ZM7 15H5C4.44772 15 4 15.4477 4 16V28C4 28.5523 4.44772 29 5 29H7C7.55228 29 8 28.5523 8 28V16C8 15.4477 7.55228 15 7 15ZM5 14C3.89543 14 3 14.8954 3 16V28C3 29.1046 3.89543 30 5 30H7C8.10457 30 9 29.1046 9 28V16C9 14.8954 8.10457 14 7 14H5Z"
      />
    </mask>
    <path
      d="M28.9779 15.3561L29.9709 15.4748L28.9779 15.3561ZM27.437 28.2373L28.4299 28.3561L27.437 28.2373ZM11.6443 3.2692C13.6666 3.95449 15.1198 5.86861 15.1198 8.11955H17.1198C17.1198 4.98561 15.0956 2.32703 12.2862 1.375L11.6443 3.2692ZM11 11.9997V3.99973H9V11.9997H11ZM9 11.9997V13.9997H11V11.9997H9ZM9 13.9997V27.9997H11V13.9997H9ZM12 30.9997H25.4512V28.9997H12V30.9997ZM28.4299 28.3561L29.9709 15.4748L27.985 15.2373L26.4441 28.1185L28.4299 28.3561ZM29.9709 15.4748C30.2556 13.0943 28.3967 10.9997 25.9992 10.9997V12.9997C27.1979 12.9997 28.1274 14.047 27.985 15.2373L29.9709 15.4748ZM25.9992 10.9997H18.1198V12.9997H25.9992V10.9997ZM15.1198 8.11955V9.99973H17.1198V8.11955H15.1198ZM5 16H7V14H5V16ZM5 16V14C3.89543 14 3 14.8954 3 16H5ZM5 28V16H3V28H5ZM5 28H5H3C3 29.1046 3.89543 30 5 30V28ZM7 28H5V30H7V28ZM7 28V30C8.10457 30 9 29.1046 9 28H7ZM7 16V28H9V16H7ZM7 16H9C9 14.8954 8.10457 14 7 14V16ZM4 16C4 15.4477 4.44772 15 5 15V13C3.34315 13 2 14.3431 2 16H4ZM4 28V16H2V28H4ZM5 29C4.44772 29 4 28.5523 4 28H2C2 29.6569 3.34315 31 5 31V29ZM7 29H5V31H7V29ZM8 28C8 28.5523 7.55228 29 7 29V31C8.65685 31 10 29.6569 10 28H8ZM8 16V28H10V16H8ZM7 15C7.55228 15 8 15.4477 8 16H10C10 14.3431 8.65685 13 7 13V15ZM5 15H7V13H5V15ZM18.1198 10.9997C17.5675 10.9997 17.1198 10.552 17.1198 9.99973H15.1198C15.1198 11.6566 16.463 12.9997 18.1198 12.9997V10.9997ZM9 27.9997C9 29.6566 10.3431 30.9997 12 30.9997V28.9997C11.4477 28.9997 11 28.552 11 27.9997H9ZM25.4512 30.9997C26.9702 30.9997 28.2495 29.8643 28.4299 28.3561L26.4441 28.1185C26.3839 28.6213 25.9575 28.9997 25.4512 28.9997V30.9997ZM12.2862 1.375C11.3841 1.06931 10.5201 1.32964 9.92003 1.87166C9.33942 2.39608 9 3.17855 9 3.99973H11C11 3.71633 11.1201 3.48274 11.2606 3.35586C11.3816 3.24658 11.5002 3.22038 11.6443 3.2692L12.2862 1.375Z"
      fill="currentColor"
      mask="url(#path-1-inside-1_4742_69233)"
    />
  </svg>
);

export const ThumbsDownIcon = (props: React.ComponentPropsWithoutRef<'svg'>) => (
  <svg
    width="32"
    height="32"
    viewBox="0 0 32 32"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <mask id="path-1-inside-1_4742_69232" fill="white">
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M22 28C22 29.1046 21.0809 30.0322 20.0348 29.6777C17.6189 28.859 15.8802 26.5726 15.8802 23.8802V22C15.8802 20.8955 14.9847 20 13.8802 20H6.00082C4.20269 20 2.80848 18.4291 3.02206 16.6437L4.56299 3.76247C4.68328 2.75696 5.53614 2.00003 6.54883 2.00003H20C21.1046 2.00003 22 2.89546 22 4.00003V19V20V28ZM27 17H25C24.4477 17 24 16.5523 24 16V4.00003C24 3.44775 24.4477 3.00003 25 3.00003H27C27.5523 3.00003 28 3.44774 28 4.00003V16C28 16.5523 27.5523 17 27 17ZM29 16C29 17.1046 28.1046 18 27 18H25C23.8954 18 23 17.1046 23 16V4.00003C23 2.89546 23.8954 2.00003 25 2.00003H27C28.1046 2.00003 29 2.89546 29 4.00003V16Z"
      />
    </mask>
    <path
      d="M3.02206 16.6437L2.02914 16.5249L3.02206 16.6437ZM4.56299 3.76247L5.55591 3.88125L4.56299 3.76247ZM14.8802 23.8802C14.8802 27.0141 16.9044 29.6727 19.7138 30.6248L20.3557 28.7306C18.3334 28.0453 16.8802 26.1311 16.8802 23.8802H14.8802ZM14.8802 22V23.8802H16.8802V22H14.8802ZM6.00082 21H13.8802V19H6.00082V21ZM2.02914 16.5249C1.74436 18.9054 3.60332 21 6.00082 21V19C4.80207 19 3.87259 17.9527 4.01498 16.7625L2.02914 16.5249ZM3.57007 3.64369L2.02914 16.5249L4.01498 16.7625L5.55591 3.88125L3.57007 3.64369ZM20 1.00003H6.54883V3.00003H20V1.00003ZM23 19V4.00003H21V19H23ZM23 20V19H21V20H23ZM23 28V20H21V28H23ZM25 18H27V16H25V18ZM23 16C23 17.1046 23.8954 18 25 18V16H23ZM23 4.00003V16H25V4.00003H23ZM25 2.00003C23.8954 2.00003 23 2.89546 23 4.00003H25V2.00003ZM27 2.00003H25V4.00003H27V2.00003ZM29 4.00003C29 2.89546 28.1046 2.00003 27 2.00003V4.00003H27H29ZM29 16V4.00003H27V16H29ZM27 18C28.1046 18 29 17.1046 29 16H27V18ZM27 19C28.6569 19 30 17.6569 30 16H28C28 16.5523 27.5523 17 27 17V19ZM25 19H27V17H25V19ZM22 16C22 17.6569 23.3431 19 25 19V17C24.4477 17 24 16.5523 24 16H22ZM22 4.00003V16H24V4.00003H22ZM25 1.00003C23.3431 1.00003 22 2.34318 22 4.00003H24C24 3.44774 24.4477 3.00003 25 3.00003V1.00003ZM27 1.00003H25V3.00003H27V1.00003ZM30 4.00003C30 2.34318 28.6569 1.00003 27 1.00003V3.00003C27.5523 3.00003 28 3.44774 28 4.00003H30ZM30 16V4.00003H28V16H30ZM5.55591 3.88125C5.61605 3.37849 6.04249 3.00003 6.54883 3.00003V1.00003C5.0298 1.00003 3.7505 2.13542 3.57007 3.64369L5.55591 3.88125ZM16.8802 22C16.8802 20.3432 15.537 19 13.8802 19V21C14.4325 21 14.8802 21.4477 14.8802 22H16.8802ZM20 3.00003C20.5523 3.00003 21 3.44775 21 4.00003H23C23 2.34318 21.6569 1.00003 20 1.00003V3.00003ZM19.7138 30.6248C20.6159 30.9304 21.4799 30.6701 22.08 30.1281C22.6606 29.6037 23 28.8212 23 28H21C21 28.2834 20.8799 28.517 20.7394 28.6439C20.6184 28.7532 20.4998 28.7794 20.3557 28.7306L19.7138 30.6248Z"
      fill="currentColor"
      mask="url(#path-1-inside-1_4742_69232)"
    />
  </svg>
);
