import classNames from 'classnames';
import * as styles from './ModalLayout.css';

interface ModalLayoutProps {
  children: React.ReactNode;
  className?: string;
}

export const ModalLayout = ({ children, className }: ModalLayoutProps) => (
  <div className={classNames(styles.modalLayout, className)}>{children}</div>
);
