import { assignInlineVars } from '@vanilla-extract/dynamic';
import classNames from 'classnames';
import { useEffect, useState } from 'react';
import {
  chipButton,
  chipContainer,
  chipGroupLabel,
  divider,
  spacing,
} from './ChipsMultiselect.css';
import { Button } from '../Button';

export interface MultiselectGroup {
  name?: string;
  id?: string;
  chips: MultiselectChip[];
}

export interface MultiselectChip {
  id: string;
  text: string;
}

interface ChipsMultiselectProps {
  /* An array of groups of chips to display */
  groups: MultiselectGroup[];
  /* The CSS value of spacing between chips */
  chipsSpacing?: string;
  /* The CSS class name to apply to the container */
  className?: string | null;
  /* The CSS class name to apply to each chip */
  chipClassName?: string | null;
  /* The callback to call when the selected values change */
  onChange: (selectedValues: string[]) => void;
}

export const ChipsMultiselect = ({
  groups,
  chipsSpacing = '6px',
  className = null,
  chipClassName = null,
  onChange,
}: ChipsMultiselectProps) => {
  const [selectedValues, setSelectedValues] = useState<Record<string, boolean>>({});

  const toggleChip = (newID: string) => {
    setSelectedValues((currentSelectedValues) => {
      const updatedSelectedValues = {
        ...currentSelectedValues,
        [newID]: !currentSelectedValues[newID],
      };
      return updatedSelectedValues;
    });
  };

  useEffect(() => {
    onChange(Object.keys(selectedValues).filter((key) => selectedValues[key]));
  }, [selectedValues, onChange]);

  return (
    <div className={classNames(className, chipContainer)}>
      {groups.length > 0 &&
        groups.map((group) => (
          <section key={group.id}>
            <h3 className={chipGroupLabel}>{group.name}</h3>
            {group.chips.map((chip) => (
              <Button
                style={assignInlineVars({
                  [spacing]: chipsSpacing,
                })}
                key={chip.id}
                isSmall
                isUppercase={false}
                colorTheme={selectedValues[chip.id] ? 'Primary' : 'Tertiary'}
                aria-labelledby={chip.text}
                role="checkbox"
                aria-checked={selectedValues[chip.id]}
                onClick={() => toggleChip(chip.id)}
                className={classNames(chipButton, chipClassName)}
              >
                <span id={chip.id}>{chip.text}</span>
              </Button>
            ))}
            <div className={divider} />
          </section>
        ))}
    </div>
  );
};

export default ChipsMultiselect;
