import { createContext, useContext, useEffect, useId, useState } from 'react';

import * as styles from './DynamicSVGSpritesheetProvider.css';

// Every sprite should be a function that takes an ID and renders a <symbol> element
type IconSpriteComponent = (props: { id: string }) => JSX.Element;

const iconSpriteContext = createContext<
  [IconSpriteComponent[], React.Dispatch<React.SetStateAction<IconSpriteComponent[]>>]
>([
  [],
  // eslint-disable-next-line @typescript-eslint/no-empty-function
  () => {},
]);

interface IconSpriteInstanceData {
  id: string;
  instanceCount: number;
}

// Maps source icon components to data tracking their instance count and unique ID;
// if an icon's instance drops to 0, we will remove it
const iconSpriteInstanceDataMap = new Map<IconSpriteComponent, IconSpriteInstanceData>();

/**
 * Provider manages rendering re-usable SVG sprites on the page and provides a hook to register an icon component
 * which references that source sprite.
 */
export function DynamicSVGSpritesheetProvider({ children }: { children: React.ReactNode }) {
  const activeSpriteComponentState = useState<IconSpriteComponent[]>(() => []);
  const [activeSpriteComponents] = activeSpriteComponentState;

  return (
    <iconSpriteContext.Provider value={activeSpriteComponentState}>
      {children}
      {/* Render all active source icons */}
      <svg xmlns="http://www.w3.org/2000/svg" className={styles.spritesheetSVG}>
        <defs>
          {/* Each sprite component should render a <symbol> into this <def> tag. We can then reference those <symbol>s like re-usable SVGs with the <use> tag */}
          {activeSpriteComponents.map((IconSprite) => {
            const iconData = iconSpriteInstanceDataMap.get(IconSprite);
            if (!iconData) {
              return null;
            }
            return <IconSprite key={iconData.id} id={iconData.id} />;
          })}
        </defs>
      </svg>
    </iconSpriteContext.Provider>
  );
}

/**
 * Hook takes an SVG source component, ensures that component is rendered on the page so it can be safely referenced,
 * and returns a unique ID for it. This ID can then be used with a `<use>` tag to reference the source icon.
 *
 * @example
 * const MyIcon = () => {
 *   const id = useSVGSprite(MyIconSource);
 *   return (
 *     <svg>
 *       <use href={`#${id}`} />
 *     </svg>
 *   );
 * };
 */
export const useSVGSprite = (spriteComponent: IconSpriteComponent) => {
  const [, setActiveSpriteComponents] = useContext(iconSpriteContext);

  const instanceID = useId();

  let iconData = iconSpriteInstanceDataMap.get(spriteComponent);
  if (!iconData) {
    // If we don't have data for this source icon, create it
    iconData = {
      id: instanceID,
      instanceCount: 0,
    };
    // If this source icon hasn't been registered yet, add it to the map
    iconSpriteInstanceDataMap.set(spriteComponent, iconData);
  }

  useEffect(() => {
    if (!iconData) {
      // We should never actually hit this case, but need to satisfy TS
      return;
    }

    if (iconData.instanceCount === 0) {
      // If this is a new instance, update the list of active source icons to reflect that we just registered a new one.
      // There is going to be one render cycle where <use> tag(s) referencing this sprite may be rendered before the sprite is available,
      // but they will automatically update once the sprite is rendered in the next cycle.
      setActiveSpriteComponents(Array.from(iconSpriteInstanceDataMap.keys()));
    }

    // Increment the instance count for this source icon
    iconData.instanceCount += 1;
  }, [iconData, setActiveSpriteComponents, spriteComponent]);

  useEffect(
    () => () => {
      const cleanupIconData = iconSpriteInstanceDataMap.get(spriteComponent);
      if (cleanupIconData) {
        cleanupIconData.instanceCount -= 1;
        if (cleanupIconData.instanceCount <= 0) {
          iconSpriteInstanceDataMap.delete(spriteComponent);
          // Update the list of active source icons to reflect that we just removed one
          setActiveSpriteComponents(Array.from(iconSpriteInstanceDataMap.keys()));
        }
      }
    },
    [setActiveSpriteComponents, spriteComponent],
  );

  return iconData.id;
};
