import { ElevenLabsIcon } from './ElevenLabsIcon';

interface AttributionIconProps {
  company: AttributionIconCompanies;
  width?: number;
  height?: number;
}

export enum AttributionIconCompanies {
  ElevenLabs = 'ElevenLabs',
}

const iconMap = {
  [AttributionIconCompanies.ElevenLabs]: ElevenLabsIcon,
};

export function AttributionIcon({ company }: AttributionIconProps) {
  return <div>{iconMap[company] && iconMap[company]()}</div>;
}

//style={{ width: width, height: height }}

export default AttributionIcon;
