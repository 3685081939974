import classNames from 'classnames';

import { CloseIcon } from '../../icons';
import * as styles from './CloseModalXButton.css';

interface CloseModalXButtonProps extends React.ComponentPropsWithoutRef<'button'> {
  onClick: () => void;
}

export const CloseModalXButton = ({ onClick, className, ...props }: CloseModalXButtonProps) => (
  <button
    type="button"
    onClick={onClick}
    className={classNames(styles.closeButton, className)}
    aria-label="Close"
    slot="close-x"
    {...props}
  >
    <CloseIcon />
  </button>
);
