import { assignInlineVars } from '@vanilla-extract/dynamic';
import { themeVars } from '@libs/shared-ui-styles/src';
import { useSVGSprite } from '../DynamicSVGSpritesheetProvider';
import { iconPrimaryColorVar, iconSecondaryColorVar } from '../iconColorVars.css';

const TVReadyIconSprite = ({ id }: { id: string }) => (
  <symbol id={id} viewBox="0 0 24 24">
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M6.5625 2.35799C6.25184 2.04733 6.25184 1.54366 6.5625 1.233C6.87316 0.922335 7.37684 0.922335 7.6875 1.233L12.125 5.6705H12.2612L16.6987 1.233C17.0094 0.922335 17.5131 0.922335 17.8237 1.233C18.1344 1.54366 18.1344 2.04733 17.8237 2.35799L14.5112 5.6705H19.8C20.9201 5.6705 21.4802 5.6705 21.908 5.88848C22.2843 6.08023 22.5903 6.38619 22.782 6.76251C23 7.19034 23 7.75039 23 8.8705V17.4705C23 18.5906 23 19.1507 22.782 19.5785C22.5903 19.9548 22.2843 20.2608 21.908 20.4525C21.4802 20.6705 20.9201 20.6705 19.8 20.6705H19.8H4.2H4.19997C3.07989 20.6705 2.51984 20.6705 2.09202 20.4525C1.71569 20.2608 1.40973 19.9548 1.21799 19.5785C1 19.1507 1 18.5906 1 17.4705V8.87049C1 7.75039 1 7.19034 1.21799 6.76251C1.40973 6.38619 1.71569 6.08023 2.09202 5.88848C2.51984 5.6705 3.0799 5.6705 4.2 5.6705H9.875L6.5625 2.35799Z"
      fill={iconPrimaryColorVar}
    />
    <path
      d="M12.2424 10.6385V9.12647H5.10242V10.6385H7.79042V17.6705H9.55442V10.6385H12.2424ZM16.2972 17.6705L18.9492 9.12647H17.1492L16.1292 12.4505C15.7812 13.6025 15.4572 14.7785 15.2052 15.9425H15.1812C14.9292 14.7785 14.6172 13.6025 14.2572 12.4505L13.2372 9.12647H11.4372L14.0892 17.6705H16.2972Z"
      fill={iconSecondaryColorVar}
    />
  </symbol>
);

interface TVReadyIconProps extends React.ComponentPropsWithRef<'svg'> {
  color?: string;
  secondaryColor?: string;
}

export const TVReadyIcon = ({
  color = 'currentColor',
  secondaryColor = themeVars.color.white,
  style,
  ...props
}: TVReadyIconProps) => {
  const iconID = useSVGSprite(TVReadyIconSprite);

  return (
    <svg
      width="24"
      height="24"
      viewBox="0 0 24 24"
      xmlns="http://www.w3.org/2000/svg"
      style={{
        ...style,
        ...assignInlineVars({
          [iconPrimaryColorVar]: color,
          [iconSecondaryColorVar]: secondaryColor,
        }),
      }}
      {...props}
    >
      <use href={`#${iconID}`} />
    </svg>
  );
};
