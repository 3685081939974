import React from 'react';
import { themeVars } from '@libs/shared-ui-styles/src';
import { assignInlineVars } from '@vanilla-extract/dynamic';
import { iconPrimaryColorVar, iconSecondaryColorVar } from '../iconColorVars.css';
import { useSVGSprite } from '../DynamicSVGSpritesheetProvider';

const DigitalOnlyIconSprite = ({ id }: { id: string }) => (
  <symbol id={id} viewBox="0 0 12 19">
    <rect width="12" height="19" rx="2" fill={iconPrimaryColorVar} />
    <rect x="2" y="2" width="8" height="13" rx="1" fill={iconSecondaryColorVar} />
    <rect x="5" y="16" width="2" height="2" rx="1" fill={iconSecondaryColorVar} />
  </symbol>
);

interface DigitalOnlyIconProps extends React.ComponentPropsWithRef<'svg'> {
  color?: string;
  secondaryColor?: string;
}

export const DigitalOnlyIcon = ({
  color = 'currentColor',
  secondaryColor = themeVars.color.white,
  style,
  ...props
}: DigitalOnlyIconProps) => {
  const iconID = useSVGSprite(DigitalOnlyIconSprite);

  return (
    <svg
      width="12"
      height="19"
      viewBox="0 0 12 19"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      style={{
        ...style,
        ...assignInlineVars({
          [iconPrimaryColorVar]: color,
          [iconSecondaryColorVar]: secondaryColor,
        }),
      }}
      {...props}
    >
      <use href={`#${iconID}`} />
    </svg>
  );
};
