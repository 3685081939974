import { useSVGSprite } from '../DynamicSVGSpritesheetProvider';

const EssentialsIconSprite = ({ id }: { id: string }) => (
  <symbol id={id} viewBox="0 0 32 32">
    <path
      d="M16.5 16C16.5 16 17.5633 24.6497 14.8811 27.3318C12.199 30.014 7.85032 30.014 5.16815 27.3318C2.48597 24.6497 2.48597 20.301 5.16815 17.6188C7.85032 14.9367 16.5 16 16.5 16Z"
      stroke="currentColor"
    />
    <path
      d="M16.5 16.0001C16.5 16.0001 25.1497 17.0634 27.8318 14.3813C30.514 11.6991 30.514 7.35044 27.8318 4.66827C25.1497 1.9861 20.801 1.9861 18.1188 4.66827C15.4367 7.35044 16.5 16.0001 16.5 16.0001Z"
      stroke="currentColor"
    />
  </symbol>
);

interface EssentialsIconProps extends React.ComponentPropsWithRef<'svg'> {
  color?: string;
}

export const EssentialsIcon = ({ color, style, ...props }: EssentialsIconProps) => {
  const iconID = useSVGSprite(EssentialsIconSprite);

  return (
    <svg
      width="32"
      height="32"
      viewBox="0 0 32 32"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      style={{
        ...style,
        color,
      }}
      {...props}
    >
      <use href={`#${iconID}`} />
    </svg>
  );
};
