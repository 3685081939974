interface IconProps extends React.ComponentPropsWithoutRef<'svg'> {
  color?: string;
}

export const DownArrowIcon = ({ color = 'currentColor', ...props }: IconProps) => (
  <svg
    width="32"
    height="32"
    viewBox="0 0 32 32"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path d="M6 10L16 21L26.5 10" stroke={color} strokeLinecap="round" strokeLinejoin="round" />
  </svg>
);
