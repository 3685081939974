import classNames from 'classnames';
import * as styles from './ModalSubheadingText.css';

interface ModalSubheadingTextProps extends React.ComponentPropsWithoutRef<'p'> {
  children: React.ReactNode;
}

export const ModalSubheadingText = ({
  children,
  className,
  ...props
}: ModalSubheadingTextProps) => (
  <p className={classNames(styles.subheadingText, className)} slot="description" {...props}>
    {children}
  </p>
);
