import { useState } from 'react';
import {
  Button,
  ListBox,
  ListBoxItem,
  ListBoxItemProps,
  Popover,
  Select,
  SelectValue,
} from 'react-aria-components';
import classNames from 'classnames';

import { DownArrowIcon } from '../icons';

import * as styles from './SelectInput.css';

interface OptionItem<TKey extends string> {
  /**
   * The name/content to display for the option in the dropdown
   */
  name: string | React.ReactNode;
  /**
   * The unique key to represent each option
   */
  key: TKey;
  /**
   * Additional props to pass to the ListBoxItem component for this option
   */
  itemProps?: Omit<ListBoxItemProps, 'children'>;
}

interface SelectInputProps<TOptionKey extends string> {
  ariaLabel?: string;
  ariaLabelledBy?: string;
  options: OptionItem<TOptionKey>[];
  selectedKey: TOptionKey;
  onSelectionChange: (newSelectedKey: TOptionKey) => void;
  className?: string;
  /**
   * Additional props to apply to each ListBoxItem component for each option
   */
  optionItemProps?: Omit<ListBoxItemProps, 'children'>;
}

type SelectInputPropsWithAriaLabel<TOptionKey extends string> = SelectInputProps<TOptionKey> &
  // One of either ariaLabel or ariaLabelledBy is required
  (| {
        ariaLabel: string;
        ariaLabelledBy?: never;
      }
    | {
        ariaLabel?: never;
        ariaLabelledBy: string;
      }
  );

/**
 * A controlled select input component that allows users to select from a list of options.
 */
export const SelectInput = <TOptionKey extends string>({
  ariaLabel,
  ariaLabelledBy,
  options,
  selectedKey,
  onSelectionChange,
  className,
  optionItemProps,
}: SelectInputPropsWithAriaLabel<TOptionKey>) => {
  const [isOpen, setIsOpen] = useState(false);

  if (!ariaLabel && !ariaLabelledBy) {
    // Harshly enforce that one of these props is required to make sure the component is accessible
    throw new Error('SelectInput requires either an ariaLabel or ariaLabelledBy prop.');
  }

  return (
    <Select
      aria-label={ariaLabel}
      aria-labelledby={ariaLabelledBy}
      onOpenChange={(newIsOpen) => setIsOpen(newIsOpen)}
      selectedKey={selectedKey}
      onSelectionChange={(newSelectedKey) => onSelectionChange(newSelectedKey as TOptionKey)}
      className={className}
    >
      <Button className={styles.selectButton} {...styles.dataIsOpen(isOpen)}>
        <SelectValue />
        <DownArrowIcon aria-hidden="true" className={styles.arrowIcon} />
      </Button>
      <Popover className={styles.popover}>
        <ListBox items={options} className={styles.listBox}>
          {(option) => {
            const listBoxItemProps = {
              // Apply base option props, then option-specific props
              ...optionItemProps,
              ...option.itemProps,
            };
            // Ensure all classNames are merged
            listBoxItemProps.className = classNames(
              styles.listBoxItem,
              optionItemProps?.className,
              option.itemProps?.className,
            );

            return <ListBoxItem {...listBoxItemProps}>{option.name}</ListBoxItem>;
          }}
        </ListBox>
      </Popover>
    </Select>
  );
};
