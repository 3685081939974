import { assignInlineVars } from '@vanilla-extract/dynamic';
import { useSVGSprite } from '../DynamicSVGSpritesheetProvider';
import { iconPrimaryColorVar } from '../iconColorVars.css';

// This icon is fairly complex and can be rendered a large number of times on a single page, so we will use a sprite to reduce duplication in the DOM.

const MediaVoiceIconSprite = ({ id }: { id: string }) => (
  <symbol viewBox="0 0 32 32" id={id}>
    <rect
      x="12"
      y="4"
      width="8"
      height="16"
      rx="4"
      stroke={iconPrimaryColorVar}
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M23 16C23 19.866 19.866 23 16 23V23C12.134 23 9 19.866 9 16"
      stroke={iconPrimaryColorVar}
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M16 23.625V27.8217"
      stroke={iconPrimaryColorVar}
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M13 28.5H19"
      stroke={iconPrimaryColorVar}
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </symbol>
);

interface MediaVoiceIconProps extends React.ComponentPropsWithRef<'svg'> {
  color?: string;
}

export const MediaVoiceIcon = ({ color, style, ...props }: MediaVoiceIconProps) => {
  const iconID = useSVGSprite(MediaVoiceIconSprite);

  return (
    <svg
      width="32"
      height="32"
      viewBox="0 0 32 32"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      style={{
        ...assignInlineVars({
          [iconPrimaryColorVar]: color,
        }),
        ...style,
      }}
      {...props}
    >
      <use href={`#${iconID}`} />
    </svg>
  );
};
