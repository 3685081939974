import { assignInlineVars } from '@vanilla-extract/dynamic';
import { ThumbsDownIcon, ThumbsUpIcon } from './ThumbIcons';
import {
  DEFAULT_SPACING,
  thumbSpacingVar,
  thumbSizeVar,
  thumbRating,
  divider,
  DEFAULT_THUMB_SIZE,
  dataHasDivider,
  positiveThumb,
  negativeThumb,
} from './ThumbRating.css';
import classNames from 'classnames';

export type ThumbRatingState = 'positive' | 'negative';

interface ThumbRatingProps {
  /**
   * The current state of the rating, if this component is being used as a controlled input.
   * If not provided, the inputs will default to both being unchecked and the state will be managed internally
   * as the user interacts with them.
   */
  state?: ThumbRatingState | null;
  /**
   * Callback to be called when the user changes the selected rating
   */
  onChange: (state: ThumbRatingState) => void;
  /**
   * CSS unit value for the amount of spacing between the thumbs
   */
  thumbSpacing?: string;
  /**
   * CSS unit value for the width of the thumbs
   */
  thumbSize?: string;
  /**
   * Whether to show a divider line between the thumbs.
   * If false, the thumb icons will be shown inside gray circles to visually separate them,
   * otherwise the thumb icons will be displayed on their own with a divider line between them.
   *
   * @default false
   */
  shouldShowDivider?: boolean;
  /**
   * Aria label to identify the purpose of the input to screen readers
   * Either this or the labelledByID prop should always be provided.
   */
  label?: string;
  /**
   * The ID of an element that labels this input for screen readers
   * Either this or the label prop should always be provided.
   */
  labelledByID?: string;
  /**
   * Optional class name to apply custom styles to the root element
   */
  className?: string;
}

/**
 * A thumbs up/down radio input group for quality ratings
 */
export const ThumbRating = ({
  state = null,
  thumbSpacing = DEFAULT_SPACING,
  thumbSize = DEFAULT_THUMB_SIZE,
  shouldShowDivider = false,
  label,
  labelledByID,
  className,
  onChange,
}: ThumbRatingProps) => (
  <fieldset
    className={classNames(thumbRating, className)}
    style={assignInlineVars({
      [thumbSpacingVar]: thumbSpacing,
      [thumbSizeVar]: thumbSize,
    })}
    aria-label={label}
    aria-labelledby={labelledByID}
    {...dataHasDivider(shouldShowDivider)}
  >
    <div className={positiveThumb}>
      <input
        aria-label="Like"
        type="radio"
        name="thumbRating"
        onChange={() => onChange('positive')}
        // Note: after the initial render, the `checked` attribute apparently
        // will not update even though the state is changing and re-renders are happening.
        // The inputs are reflecting the correct state, so this seems like a React quirk? Regardless,
        // it's fine, just make sure to use `input:checked` in selectors instead of `input[checked]`
        checked={state === 'positive'}
      />
      <ThumbsUpIcon />
    </div>
    <div className={divider} />
    <div className={negativeThumb}>
      <input
        aria-label="Dislike"
        type="radio"
        name="thumbRating"
        onChange={() => onChange('negative')}
        checked={state === 'negative'}
      />
      <ThumbsDownIcon />
    </div>
  </fieldset>
);
